import axios from 'axios';
import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';

function Login(props){

    localStorage.removeItem("logged");
    localStorage.removeItem("uName");
    localStorage.setItem("logged", false);
    localStorage.setItem("uName", "");

    console.log(localStorage.getItem("logged"));
    const navigate = useNavigate();
    const[loginData,setLoginData]=useState(
        {
            Username:"",
            Password:""
        }
      )
    const[logged, setLogged] = useState("");

      function handleChange(event)
      {
        event.preventDefault();
        const id = event.target.id;
        const value = event.target.value;
        setLoginData(values => ({...values, [id]: value}))
        
      }

      const handleSubmit = (event) =>
      {
        axios.post("https://nodeppcbackend-production.up.railway.app/api/Login",{
            Username : loginData.Username.toString(),
            Password : loginData.Password.toString()
        })
        .then((response) => {    
            if(response.data !== "Error"){     
                window.localStorage.removeItem("logged");
                window.localStorage.setItem("logged", true);
                window.localStorage.removeItem("uName");
                window.localStorage.setItem("uName", response.data);
                navigate("/Admin/Dashboard");
            }
            else{
                navigate("/Admin");
                alert("Utilizator sau parola gresita!");
            }
        })
        .catch(function (error) {
            alert("Something went wrong!");
          });
      }

    return(
        <div className="container" style={{width:'500px', marginTop: "20vh"}}>
            <div className="card shadow-lg">
                <div className="card-body p-5 text-center">
                    <form>
                        <h2 className="fw-bold mb-2 text-uppercase">Admin Login</h2>
                        <p className="mb-5">Please enter your user and password!</p>
                        <div className="mb-5 pt-4">
                            <input type="text" class="form-control rounded-pill shadow bottom" placeholder="Username" aria-label="Username" id="Username" onChange={handleChange}></input>
                        </div>
                        <div className="mb-4">
                            <input type="password" class="form-control rounded-pill shadow bottom" placeholder="Password" aria-label="Password" id="Password" onChange={handleChange}></input>
                        </div>
                        <div className="mb-5 pt-4">
                            <button type="submit" class="w-50 btn btn-success rounded-pill" onClick={handleSubmit}>Login</button>
                        </div>
                    </form>
                </div>
            </div> 
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
}

export default Login;