import { HashRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/pages/Home';
import NationalChampionship from './components/pages/NationalChampionship';
import EuropeanChampionship from './components/pages/EuropeanChampionship';
import Results from './components/pages/Results';
import LiveResults from './components/pages/LiveResults';
import Info from './components/pages/Info';
import Login from "./admin/components/pages/Login";
import AdminDashboard from './admin/components/pages/AdminDashboard';
import AddNewCompetitor from './admin/components/pages/AddNewCompetitor';
import Scoring from './admin/components/pages/Scoring';
import PdfGeneration from './admin/components/pages/PdfGeneration';
import PdfDownloader from './admin/components/externals/PdfDownloader';
import Overview from './admin/components/pages/Overview';
import SearchResults from './components/pages/SearchResults';

function App() {

  console.log("App!");
  if(window.localStorage.getItem("logged") === null){
      console.log("App if!")
      window.localStorage.setItem("logged", false);
      window.localStorage.setItem("uName", "");
      window.localStorage.setItem("refresher", "0")
      window.localStorage.setItem("pdf_file_name", "")
  }
  
  if(window.sessionStorage.getItem("trials_index") === null){
    window.sessionStorage.setItem("trials_index", "0");
    window.sessionStorage.setItem("classif_index", "0");
    window.sessionStorage.setItem("modified", "false");
    window.localStorage.setItem("lenght", "0");
  }
  
  return (
    <div>
    <Router>
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />  
        <Route path="/Home" element={<Home />} />
        <Route path="/Events/National Championship" element={<NationalChampionship />} />
        <Route path="/Events/European Championship" element={<EuropeanChampionship />} />
        <Route path="/Results" element={<Results />} />
        <Route path="/Info" element={<Info />} />
        <Route path="/Live" element={<LiveResults />} />
        <Route path="/Search Results" element={<SearchResults />} />

        <Route path="/Admin" element={<Login />} />
        <Route path="/Admin/Dashboard" element={<AdminDashboard />} />

        <Route path="/Adaugare/CN/Etapa1" element={<AddNewCompetitor stage="etapa1"/>}/>
        <Route path="/Adaugare/CN/Etapa2" element={<AddNewCompetitor stage="etapa2"/>}/>
        <Route path="/Adaugare/CN/Etapa3" element={<AddNewCompetitor stage="etapa3"/>}/>
        <Route path="/Adaugare/CN/Etapa4" element={<AddNewCompetitor stage="etapa4"/>}/>
        <Route path="/Adaugare/CN/Open" element={<AddNewCompetitor stage="open_tm"/>}/>
        <Route path="/Adaugare/CN/Etapa5" element={<AddNewCompetitor stage="etapa5"/>}/>
        <Route path="/Adaugare/CN/Etapa6" element={<AddNewCompetitor stage="etapa6"/>}/>
        <Route path="/Adaugare/CN/Etapa7" element={<AddNewCompetitor stage="etapa7"/>}/>
        <Route path="/Adaugare/CN/Etapa8" element={<AddNewCompetitor stage="etapa8"/>}/> 
        <Route path="/Adaugare/CE" element={<AddNewCompetitor stage="ce2024"/>}/>

        <Route path="/Overview/CN/Etapa1" element={<Overview stage="etapa1"/>}/>
        <Route path="/Overview/CN/Etapa2" element={<Overview stage="etapa2"/>}/>
        <Route path="/Overview/CN/Etapa3" element={<Overview stage="etapa3"/>}/>
        <Route path="/Overview/CN/Etapa4" element={<Overview stage="etapa4"/>}/>
        <Route path="/Overview/CN/Open" element={<Overview stage="open_tm"/>}/>
        <Route path="/Overview/CN/Etapa5" element={<Overview stage="etapa5"/>}/>
        <Route path="/Overview/CN/Etapa6" element={<Overview stage="etapa6"/>}/>
        <Route path="/Overview/CN/Etapa7" element={<Overview stage="etapa7"/>}/>
        <Route path="/Overview/CN/Etapa8" element={<Overview stage="etapa8"/>}/> 
        <Route path="/Overview/CE" element={<Overview stage="ce2024"/>}/>
        <Route path="/Overview/CETEAMS" element={<Overview stage="ce2024_tm"/>}/>

        <Route path="/Punctaj/CN/Etapa1/R1500" element={<Scoring stage="etapa1" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa1/P1500" element={<Scoring stage="etapa1" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa1/OS1500" element={<Scoring stage="etapa1" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa1/PCC1500" element={<Scoring stage="etapa1" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa1/DR" element={<Scoring stage="etapa1" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa1/DP" element={<Scoring stage="etapa1" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa1/OpenRevolver" element={<Scoring stage="etapa1" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa1/OpenPistol" element={<Scoring stage="etapa1" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa1/SSAP5.5" element={<Scoring stage="etapa1" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa1/SSAP3.65" element={<Scoring stage="etapa1" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa1/SR2.755shot" element={<Scoring stage="etapa1" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa1/SR4.25" element={<Scoring stage="etapa1" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa1/SR2.75" element={<Scoring stage="etapa1" trial= "SR2.75" shots="48"/>} />   

        <Route path="/Punctaj/CN/Etapa2/R1500" element={<Scoring stage="etapa2" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa2/P1500" element={<Scoring stage="etapa2" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa2/OS1500" element={<Scoring stage="etapa2" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa2/PCC1500" element={<Scoring stage="etapa2" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa2/DR" element={<Scoring stage="etapa2" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa2/DP" element={<Scoring stage="etapa2" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa2/OpenRevolver" element={<Scoring stage="etapa2" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa2/OpenPistol" element={<Scoring stage="etapa2" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa2/SSAP5.5" element={<Scoring stage="etapa2" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa2/SSAP3.65" element={<Scoring stage="etapa2" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa2/SR2.755shot" element={<Scoring stage="etapa2" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa2/SR4.25" element={<Scoring stage="etapa2" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa2/SR2.75" element={<Scoring stage="etapa2" trial= "SR2.75" shots="48"/>} />   

        <Route path="/Punctaj/CN/Etapa3/R1500" element={<Scoring stage="etapa3" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa3/P1500" element={<Scoring stage="etapa3" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa3/OS1500" element={<Scoring stage="etapa3" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa3/PCC1500" element={<Scoring stage="etapa3" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa3/DR" element={<Scoring stage="etapa3" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa3/DP" element={<Scoring stage="etapa3" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa3/OpenRevolver" element={<Scoring stage="etapa3" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa3/OpenPistol" element={<Scoring stage="etapa3" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa3/SSAP5.5" element={<Scoring stage="etapa3" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa3/SSAP3.65" element={<Scoring stage="etapa3" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa3/SR2.755shot" element={<Scoring stage="etapa3" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa3/SR4.25" element={<Scoring stage="etapa3" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa3/SR2.75" element={<Scoring stage="etapa3" trial= "SR2.75" shots="48"/>} />    

        <Route path="/Punctaj/CN/Etapa4/R1500" element={<Scoring stage="etapa4" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa4/P1500" element={<Scoring stage="etapa4" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa4/OS1500" element={<Scoring stage="etapa4" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa4/PCC1500" element={<Scoring stage="etapa4" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa4/DR" element={<Scoring stage="etapa4" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa4/DP" element={<Scoring stage="etapa4" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa4/OpenRevolver" element={<Scoring stage="etapa4" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa4/OpenPistol" element={<Scoring stage="etapa4" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa4/SSAP5.5" element={<Scoring stage="etapa4" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa4/SSAP3.65" element={<Scoring stage="etapa4" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa4/SR2.755shot" element={<Scoring stage="etapa4" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa4/SR4.25" element={<Scoring stage="etapa4" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa4/SR2.75" element={<Scoring stage="etapa4" trial= "SR2.75" shots="48"/>} />    

        <Route path="/Punctaj/CN/Etapa5/R1500" element={<Scoring stage="etapa5" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa5/P1500" element={<Scoring stage="etapa5" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa5/OS1500" element={<Scoring stage="etapa5" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa5/PCC1500" element={<Scoring stage="etapa5" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa5/DR" element={<Scoring stage="etapa5" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa5/DP" element={<Scoring stage="etapa5" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa5/OpenRevolver" element={<Scoring stage="etapa5" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa5/OpenPistol" element={<Scoring stage="etapa5" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa5/SSAP5.5" element={<Scoring stage="etapa5" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa5/SSAP3.65" element={<Scoring stage="etapa5" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa5/SR2.755shot" element={<Scoring stage="etapa5" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa5/SR4.25" element={<Scoring stage="etapa5" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa5/SR2.75" element={<Scoring stage="etapa5" trial= "SR2.75" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa5/SSAP5.5Special" element={<Scoring stage="etapa5" trial= "SSA P5.5 Special" shots="48"/>} />

        <Route path="/Punctaj/CN/Open/Pistol" element={<Scoring stage="open_tm" trial= "Pistol" shots="60"/>} /> 
        <Route path="/Punctaj/CN/Open/Revolver" element={<Scoring stage="open_tm" trial= "Revolver" shots="60"/>}/>
        <Route path="/Punctaj/CN/Open/Optic" element={<Scoring stage="open_tm" trial= "Optic" shots="60"/>}/>
        <Route path="/Punctaj/CN/Open/PCC" element={<Scoring stage="open_tm" trial= "PCC" shots="60"/>}/>        

        <Route path="/Punctaj/CN/Etapa6/R1500" element={<Scoring stage="etapa6" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa6/P1500" element={<Scoring stage="etapa6" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa6/OS1500" element={<Scoring stage="etapa6" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa6/PCC1500" element={<Scoring stage="etapa6" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa6/DR" element={<Scoring stage="etapa6" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa6/DP" element={<Scoring stage="etapa6" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa6/OpenRevolver" element={<Scoring stage="etapa6" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa6/OpenPistol" element={<Scoring stage="etapa6" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa6/SSAP5.5" element={<Scoring stage="etapa6" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa6/SSAP3.65" element={<Scoring stage="etapa6" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa6/SR2.755shot" element={<Scoring stage="etapa6" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa6/SR4.25" element={<Scoring stage="etapa6" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa6/SR2.75" element={<Scoring stage="etapa6" trial= "SR2.75" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa6/SSAP5.5Special" element={<Scoring stage="etapa6" trial= "SSA P5.5 Special" shots="48"/>} />    

        <Route path="/Punctaj/CN/Etapa7/R1500" element={<Scoring stage="etapa7" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa7/P1500" element={<Scoring stage="etapa7" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa7/OS1500" element={<Scoring stage="etapa7" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa7/PCC1500" element={<Scoring stage="etapa7" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa7/DR" element={<Scoring stage="etapa7" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa7/DP" element={<Scoring stage="etapa7" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa7/OpenRevolver" element={<Scoring stage="etapa7" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa7/OpenPistol" element={<Scoring stage="etapa7" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa7/SSAP5.5" element={<Scoring stage="etapa7" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa7/SSAP3.65" element={<Scoring stage="etapa7" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa7/SR2.755shot" element={<Scoring stage="etapa7" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa7/SR4.25" element={<Scoring stage="etapa7" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa7/SR2.75" element={<Scoring stage="etapa7" trial= "SR2.75" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa7/SSAP5.5Special" element={<Scoring stage="etapa7" trial= "SSA P5.5 Special" shots="48"/>} />    

        <Route path="/Punctaj/CN/Etapa8/R1500" element={<Scoring stage="etapa8" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CN/Etapa8/P1500" element={<Scoring stage="etapa8" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa8/OS1500" element={<Scoring stage="etapa8" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa8/PCC1500" element={<Scoring stage="etapa8" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CN/Etapa8/DR" element={<Scoring stage="etapa8" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa8/DP" element={<Scoring stage="etapa8" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa8/OpenRevolver" element={<Scoring stage="etapa8" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa8/OpenPistol" element={<Scoring stage="etapa8" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CN/Etapa8/SSAP5.5" element={<Scoring stage="etapa8" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa8/SSAP3.65" element={<Scoring stage="etapa8" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa8/SR2.755shot" element={<Scoring stage="etapa8" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CN/Etapa8/SR4.25" element={<Scoring stage="etapa8" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa8/SR2.75" element={<Scoring stage="etapa8" trial= "SR2.75" shots="48"/>} />
        <Route path="/Punctaj/CN/Etapa8/SSAP5.5Special" element={<Scoring stage="etapa8" trial= "SSA P5.5 Special" shots="48"/>} />    

        <Route path="/Punctaj/CE/R1500" element={<Scoring stage="ce2024" trial= "R1500" shots="150"/>} /> 
        <Route path="/Punctaj/CE/P1500" element={<Scoring stage="ce2024" trial= "P1500" shots="150"/>}/>
        <Route path="/Punctaj/CE/OS1500" element={<Scoring stage="ce2024" trial= "OS1500" shots="150"/>}/>
        <Route path="/Punctaj/CE/PCC1500" element={<Scoring stage="ce2024" trial= "PCC1500" shots="150"/>}/>
        <Route path="/Punctaj/CE/DR" element={<Scoring stage="ce2024" trial= "DR" shots="60"/>} />
        <Route path="/Punctaj/CE/DP" element={<Scoring stage="ce2024" trial= "DP" shots="60"/>} />
        <Route path="/Punctaj/CE/OpenRevolver" element={<Scoring stage="ce2024" trial= "Open Revolver" shots="60"/>} />
        <Route path="/Punctaj/CE/OpenPistol" element={<Scoring stage="ce2024" trial= "Open Pistol" shots="60"/>} />
        <Route path="/Punctaj/CE/SSAP5.5" element={<Scoring stage="ce2024" trial= "SSA P5.5" shots="48"/>} />
        <Route path="/Punctaj/CE/SSAP3.65" element={<Scoring stage="ce2024" trial= "SSA P3.65" shots="48"/>} />
        <Route path="/Punctaj/CE/SR2.755shot" element={<Scoring stage="ce2024" trial= "SR2.75 5 shot" shots="40"/>} />
        <Route path="/Punctaj/CE/SR4.25" element={<Scoring stage="ce2024" trial= "SR4.25" shots="48"/>} />
        <Route path="/Punctaj/CE/SR2.75" element={<Scoring stage="ce2024" trial= "SR2.75" shots="48"/>} />
        <Route path="/Punctaj/CE/SSAP5.5Special" element={<Scoring stage="ce2024" trial= "SSA P5.5 Special" shots="48"/>} />

        <Route path="/Punctaj/CETEAMS/Pistol" element={<Scoring stage="ce2024_tm" trial= "Pistol" shots="60"/>} /> 
        <Route path="/Punctaj/CETEAMS/Revolver" element={<Scoring stage="ce2024_tm" trial= "Revolver" shots="60"/>}/>
        <Route path="/Punctaj/CETEAMS/Optic" element={<Scoring stage="ce2024_tm" trial= "Optic" shots="60"/>}/>
        <Route path="/Punctaj/CETEAMS/PCC" element={<Scoring stage="ce2024_tm" trial= "PCC" shots="60"/>}/>   

        <Route path="/PDF/CN/Etapa1" element={<PdfGeneration stage="etapa1" />} />
        <Route path="/PDF/CN/Etapa2" element={<PdfGeneration stage="etapa2" />} />
        <Route path="/PDF/CN/Etapa3" element={<PdfGeneration stage="etapa3" />} />
        <Route path="/PDF/CN/Etapa4" element={<PdfGeneration stage="etapa4" />} />
        <Route path="/PDF/CN/Open" element={<PdfGeneration stage="open_tm" />} />
        <Route path="/PDF/CN/Etapa5" element={<PdfGeneration stage="etapa5" />} />
        <Route path="/PDF/CN/Etapa6" element={<PdfGeneration stage="etapa6" />} />
        <Route path="/PDF/CN/Etapa7" element={<PdfGeneration stage="etapa7" />} />
        <Route path="/PDF/CN/Etapa8" element={<PdfGeneration stage="etapa8" />} />
        <Route path="/PDF/CE" element={<PdfGeneration stage="ce2024" />} />
        <Route path="/PDF/CETEAMS" element={<PdfGeneration stage="ce2024_tm" />} />

        <Route path="/Downloads" element={<PdfDownloader stage={window.localStorage.getItem("pdf_file_name")}/>} /> 
      </Routes>
    </div>
    </Router>
    </div>
  );
}

export default App;