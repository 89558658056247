import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table150Shots from "./Table150Shots";
import "./scoringModal.css";
import { useState, useEffect} from 'react';
import Table60Shots from './Table60Shots';
import Table48Shots from './Table48Shots';
import Table40Shots from './Table40Shots';
import axios from 'axios';


function ScoringModal(props){
  
  const [show, setShow] = useState(true);
  const [values40shots, set40Value] = useState({
    M1_X:'0',      
    M1_10:'0',     
    M1_9:'0',      
    M1_8:'0',         
    M1_7:'0',      
    M1_0:'0',      
    M1_M:'0',
    M2_X:'0',
    M2_10:'0',     
    M2_9:'0',      
    M2_8:'0',         
    M2_7:'0',      
    M2_0:'0',      
    M2_M:'0',
  });

  const [values48shots, set48Value] = useState({
    M1_X:'0',      
    M1_10:'0',     
    M1_9:'0',      
    M1_8:'0',         
    M1_7:'0',      
    M1_0:'0',      
    M1_M:'0',
    M2_X:'0',
    M2_10:'0',     
    M2_9:'0',      
    M2_8:'0',         
    M2_7:'0',      
    M2_0:'0',      
    M2_M:'0',
  });

  const [values60shots, set60Value] = useState({
    M1_X:'0',      
    M1_10:'0',     
    M1_9:'0',      
    M1_8:'0',         
    M1_7:'0',      
    M1_0:'0',      
    M1_M:'0',
    M2_X:'0',
    M2_10:'0',     
    M2_9:'0',      
    M2_8:'0',         
    M2_7:'0',      
    M2_0:'0',      
    M2_M:'0',
  });

  const [values150shots, set150Value] = useState({
    M1_X:'0',      
    M1_10:'0',     
    M1_9:'0',      
    M1_8:'0',         
    M1_7:'0',      
    M1_0:'0',      
    M1_M:'0',
    M2_X:'0',
    M2_10:'0',     
    M2_9:'0',      
    M2_8:'0',         
    M2_7:'0',      
    M2_0:'0',      
    M2_M:'0',
    M3_X:'0',      
    M3_10:'0',     
    M3_9:'0',      
    M3_8:'0',         
    M3_7:'0',      
    M3_0:'0',      
    M3_M:'0',
    M4_X:'0',
    M4_10:'0',     
    M4_9:'0',      
    M4_8:'0',         
    M4_7:'0',      
    M4_0:'0',      
    M4_M:'0',
    M5_S1S2_X:'0',      
    M5_S1S2_10:'0',     
    M5_S1S2_9:'0',      
    M5_S1S2_8:'0',         
    M5_S1S2_7:'0',      
    M5_S1S2_0:'0',      
    M5_S1S2_M:'0',
    M5_S3S4_X:'0',
    M5_S3S4_10:'0',     
    M5_S3S4_9:'0',      
    M5_S3S4_8:'0',         
    M5_S3S4_7:'0',      
    M5_S3S4_0:'0',      
    M5_S3S4_M:'0',
  });

  const [sum1, setSum1] = useState(0)
  const [sum2, setSum2] = useState(0)
  const [sum3, setSum3] = useState(0)
  const [sum4, setSum4] = useState(0)
  const [sum5, setSum5] = useState(0)
  const [sum6, setSum6] = useState(0)
  const handleClose = () => {setShow(false); window.location.reload(false);}
  const refreshAfterUpdate = () =>{setShow(false); window.location.reload(false);}

  const handle40Scoring = () =>{
    console.log(values40shots)
    axios.post('https://nodeppcbackend-production.up.railway.app/api/score40Shots',{
      Values: values40shots,
      Stage: props.stage,
      Trial: props.trial,
      Lastname: props.lastname,
      Firstname: props.firstname

    })
    .then((response) => {
      if(response.data === "Success!")
      {
        //alert("Salvat!");
        refreshAfterUpdate();
      }
      else if(response.data === "ErrorM1M2"){
        alert("Valori pentru M1 si M2 gresite!");
      }
      else if(response.data === "ErrorM1"){
        alert("Valori pentru M1 gresite!");
      }
      else if(response.data === "ErrorM2"){
        alert("Valori pentru M2 gresite!"); 
      }
      
    })
  }

  function handle40Change(event){
    const id = event.target.id;
    var value = event.target.value;
    if(event.target.value === "") value = "0"
    set40Value(values => ({...values, [id]: value}))
  }

  const handle48Scoring = () =>{
    console.log(values48shots)
    axios.post('https://nodeppcbackend-production.up.railway.app/api/score48Shots',{
      Values: values48shots,
      Stage: props.stage,
      Trial: props.trial,
      Lastname: props.lastname,
      Firstname: props.firstname

    })
    .then((response) => {
      if(response.data === "Success!")
      {
        //alert("Salvat!");
        refreshAfterUpdate();
      }
      else if(response.data === "ErrorM1M2"){
        alert("Valori pentru M1 si M2 gresite!");
      }
      else if(response.data === "ErrorM1"){
        alert("Valori pentru M1 gresite!");
      }
      else if(response.data === "ErrorM2"){
        alert("Valori pentru M2 gresite!"); 
      }
      
    })
  }

  function handle48Change(event){
    const id = event.target.id;
    var value = event.target.value;
    if(event.target.value === "") value = "0"
    set48Value(values => ({...values, [id]: value}))
  }

  const handle60Scoring = () =>{

    console.log(values60shots)
    axios.post('https://nodeppcbackend-production.up.railway.app/api/score60Shots',{
      Values: values60shots,
      Stage: props.stage,
      Trial: props.trial,
      Lastname: props.lastname,
      Firstname: props.firstname

    })
    .then((response) => {
      if(response.data === "Success!")
      {
        //alert("Salvat!");
        refreshAfterUpdate();
      }
      else if(response.data === "ErrorM1M2"){
        alert("Valori pentru M1 si M2 gresite!");
      }
      else if(response.data === "ErrorM1"){
        alert("Valori pentru M1 gresite!");
      }
      else if(response.data === "ErrorM2"){
        alert("Valori pentru M2 gresite!"); 
      }
      
    })

  }

  const handleTeamsScoring = () =>{

    console.log(values60shots)
    axios.post('https://nodeppcbackend-production.up.railway.app/api/scoreTeams',{
      Values: values60shots,
      Stage: props.stage,
      Trial: props.trial,
      Lastname: props.lastname,
      Firstname: props.firstname,
      teamId : props.teamID

    })
    .then((response) => {
      if(response.data === "Success!")
      {
        //alert("Salvat!");
        refreshAfterUpdate();
      }
      else if(response.data === "ErrorM1M2"){
        alert("Valori pentru M1 si M2 gresite!");
      }
      else if(response.data === "ErrorM1"){
        alert("Valori pentru M1 gresite!");
      }
      else if(response.data === "ErrorM2"){
        alert("Valori pentru M2 gresite!"); 
      }
      
    })

  }


  function handle60Change(event){

    const id = event.target.id;
    var value = event.target.value;
    if(event.target.value === "") value = "0"
    set60Value(values => ({...values, [id]: value}))

  }

  const handle150Scoring = () => {

    console.log(values150shots)
    axios.post('https://nodeppcbackend-production.up.railway.app/api/score150Shots',{
      Values: values150shots,
      Stage: props.stage,
      Trial: props.trial,
      Lastname: props.lastname,
      Firstname: props.firstname

    })
    .then((response) => {
      if(response.data === "Success!")
      {
        //alert("Salvat!");
        refreshAfterUpdate();
      }
      else if(response.data === "ScoringError!"){
        alert("Valori gresite!");
      }
      })

  }

  function handle150Change(event){
   
    const id = event.target.id;
    var value = event.target.value;
    if(event.target.value === "") value = "0"
    set150Value(values => ({...values, [id]: value}))
    
  }

  useEffect(() => {
    setSum1(0)
    setSum2(0)    
    setSum1(Number(values40shots.M1_X)+Number(values40shots.M1_10)+Number(values40shots.M1_9)+Number(values40shots.M1_8)+Number(values40shots.M1_7)+Number(values40shots.M1_0)+Number(values40shots.M1_M))
    setSum2(Number(values40shots.M2_X)+Number(values40shots.M2_10)+Number(values40shots.M2_9)+Number(values40shots.M2_8)+Number(values40shots.M2_7)+Number(values40shots.M2_0)+Number(values40shots.M2_M))
  }, [values40shots])

  useEffect(() => {
    setSum1(0)
    setSum2(0)    
    setSum1(Number(values48shots.M1_X)+Number(values48shots.M1_10)+Number(values48shots.M1_9)+Number(values48shots.M1_8)+Number(values48shots.M1_7)+Number(values48shots.M1_0)+Number(values48shots.M1_M))
    setSum2(Number(values48shots.M2_X)+Number(values48shots.M2_10)+Number(values48shots.M2_9)+Number(values48shots.M2_8)+Number(values48shots.M2_7)+Number(values48shots.M2_0)+Number(values48shots.M2_M))
  }, [values48shots])

 useEffect(() => {
    setSum1(0)
    setSum2(0)    
    setSum1(Number(values60shots.M1_X)+Number(values60shots.M1_10)+Number(values60shots.M1_9)+Number(values60shots.M1_8)+Number(values60shots.M1_7)+Number(values60shots.M1_0)+Number(values60shots.M1_M))
    setSum2(Number(values60shots.M2_X)+Number(values60shots.M2_10)+Number(values60shots.M2_9)+Number(values60shots.M2_8)+Number(values60shots.M2_7)+Number(values60shots.M2_0)+Number(values60shots.M2_M))
  }, [values60shots])

  useEffect(() => {
    setSum1(0)
    setSum2(0)
    setSum3(0)
    setSum4(0) 
    setSum5(0)
    setSum6(0)    
    setSum1(Number(values150shots.M1_X)+Number(values150shots.M1_10)+Number(values150shots.M1_9)+Number(values150shots.M1_8)+Number(values150shots.M1_7)+Number(values150shots.M1_0)+Number(values150shots.M1_M))
    setSum2(Number(values150shots.M2_X)+Number(values150shots.M2_10)+Number(values150shots.M2_9)+Number(values150shots.M2_8)+Number(values150shots.M2_7)+Number(values150shots.M2_0)+Number(values150shots.M2_M))
    setSum3(Number(values150shots.M3_X)+Number(values150shots.M3_10)+Number(values150shots.M3_9)+Number(values150shots.M3_8)+Number(values150shots.M3_7)+Number(values150shots.M3_0)+Number(values150shots.M3_M))
    setSum4(Number(values150shots.M4_X)+Number(values150shots.M4_10)+Number(values150shots.M4_9)+Number(values150shots.M4_8)+Number(values150shots.M4_7)+Number(values150shots.M4_0)+Number(values150shots.M4_M))
    setSum5(Number(values150shots.M5_S1S2_X)+Number(values150shots.M5_S1S2_10)+Number(values150shots.M5_S1S2_9)+Number(values150shots.M5_S1S2_8)+Number(values150shots.M5_S1S2_7)+Number(values150shots.M5_S1S2_0)+Number(values150shots.M5_S1S2_M))
    setSum6(Number(values150shots.M5_S3S4_X)+Number(values150shots.M5_S3S4_10)+Number(values150shots.M5_S3S4_9)+Number(values150shots.M5_S3S4_8)+Number(values150shots.M5_S3S4_7)+Number(values150shots.M5_S3S4_0)+Number(values150shots.M5_S3S4_M))
  }, [values150shots])



  if(props.shots==="150"){
    return(
      <div>
        <Modal dialogClassName="modal-show" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><div className='my-1'>{props.firstname} {props.lastname} {props.waid}</div></Modal.Title>
          </Modal.Header>
            <Modal.Body>
          <Table150Shots onChange = {handle150Change} values={values150shots} />
            </Modal.Body>
          <Modal.Footer>
            <Button variant="success mb-3 rounded-pill" onClick={handle150Scoring} disabled={(sum1 === 24)&&(sum2 === 18)&&(sum3 === 24)&&(sum4 === 24)&&(sum5 === 30)&&(sum6 === 30) ? false:true }>Adaugare</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else if(props.shots==="60" && props.stage !== "ce2024_tm"){
    return(
      <div>
        <Modal dialogClassName="modal-show" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><div className='my-1'>{props.firstname} {props.lastname} {props.waid}</div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table60Shots  onChange = {handle60Change} values={values60shots} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success mb-3 rounded-pill" onClick={handle60Scoring} disabled={(sum1 === 30)&&(sum2 === 30) ? false:true }>Adaugare</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else if(props.shots==="60" && props.stage === "ce2024_tm"){
    return(
      <div>
        <Modal dialogClassName="modal-show" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><div className='my-1'>{props.firstname} {props.lastname} {props.waid} {props.teamID}</div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table60Shots  onChange = {handle60Change} values={values60shots} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success mb-3 rounded-pill" onClick={handleTeamsScoring} disabled={(sum1 === 30)&&(sum2 === 30) ? false:true }>Adaugare</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else if(props.shots==="48"){
    return(
      <div>
        <Modal dialogClassName="modal-show" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><div className='my-1'>{props.firstname} {props.lastname} {props.waid}</div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table48Shots onChange = {handle48Change} values={values48shots} />
          </Modal.Body>
          <Modal.Footer>
              <Button variant="success mb-3 rounded-pill" onClick={handle48Scoring} disabled={(sum1 === 18)&&(sum2 === 30) ? false:true }>Adaugare</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else if(props.shots==="40"){
    return(
      <div>
        <Modal dialogClassName="modal-show" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><div className='my-1'>{props.firstname} {props.lastname} {props.waid}</div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table40Shots onChange = {handle40Change} values={values40shots} />
          </Modal.Body>
          <Modal.Footer>
              <Button variant="success mb-3 rounded-pill" onClick={handle40Scoring} disabled={(sum1 === 15)&&(sum2 === 25) ? false:true }>Adaugare</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ScoringModal;