import Navbar from '../externals/Navbar';

function EuropeanChampionship(){
    return(
        <div>
            <Navbar />
                
            
            <div className="container text-center">             
                    
            <iframe
            width="auto" height="900" className="col-lg-12 col-md-12 col-sm-12"
            srcDoc={`
                    <!doctype html>
                    <html>
                    <head>
                        <title>Tickets ordering</title>
                        <meta charset="utf-8">
                    </head>
                    <body align="center">
                    <script type="text/javascript" src="https://l.oveit.com/embed/7b9545f03b.js?l=en" data-eventid="7b9545f03b" data-init="pending"></script>
                    </body>
                    </html>
                `} 
            />
                    
            </div>
            
        </div>
    )
}

export default EuropeanChampionship;