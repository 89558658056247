import { useNavigate } from "react-router";
import SideNavbar from "../externals/SideNavbar";

function AdminDashboard(){

    var user = window.localStorage.getItem("uName");
    var isLogged = window.localStorage.getItem("logged");
    console.log(user);

    const navigate = useNavigate();

    if(isLogged === "true"){
    
        return(
        <>
            <SideNavbar  username={user}/>
        </>
        );
    }
    else
    {
        return(
            <>
                <p>You have to be logged in to see this page!</p>
            </>
            );
        
    }
}

export default AdminDashboard;