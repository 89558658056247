function Table40Shots(props){

    return(
        <div>
            <table className="table table-borderless text-center" style={{width:"auto", height:"auto"}}>
            <thead>
                <tr className="table-dark">
                    <th scope="col"></th>
                    <th scope="col">X</th>
                    <th scope="col">10</th>
                    <th scope="col">9</th>
                    <th scope="col">8</th>
                    <th scope="col">7</th>
                    <th scope="col">0</th>
                    <th scope="col">M</th>
                    <th scope="col">Total</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody className="table-secondary">
                <tr>
                    <td>M1</td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_X" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_10" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_9" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_8" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_7" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_0" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M1_M" onChange={props.onChange}></input></td>
                    <td className="text-center">15</td>
                    {Number(props.values.M1_X)+Number(props.values.M1_10)+Number(props.values.M1_9)+Number(props.values.M1_8)+Number(props.values.M1_7)+Number(props.values.M1_0)+Number(props.values.M1_M) === 15 ? (<td className = "text-center text-success">{Number(props.values.M1_X)+Number(props.values.M1_10)+Number(props.values.M1_9)+Number(props.values.M1_8)+Number(props.values.M1_7)+Number(props.values.M1_0)+Number(props.values.M1_M)}</td>) : (<td className = "text-center text-danger">{Number(props.values.M1_X)+Number(props.values.M1_10)+Number(props.values.M1_9)+Number(props.values.M1_8)+Number(props.values.M1_7)+Number(props.values.M1_0)+Number(props.values.M1_M)}</td>)}
                </tr>
                <tr>
                    <td>M2</td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_X" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_10" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_9" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_8" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_7" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_0" onChange={props.onChange}></input></td>
                    <td><input type="text" className="w-75 text-center bg-transparent border-0" placeholder="0" id="M2_M" onChange={props.onChange}></input></td>
                    <td className="text-center">25</td>
                    {Number(props.values.M2_X)+Number(props.values.M2_10)+Number(props.values.M2_9)+Number(props.values.M2_8)+Number(props.values.M2_7)+Number(props.values.M2_0)+Number(props.values.M2_M) === 25 ? (<td className = "text-center text-success"> {Number(props.values.M2_X)+Number(props.values.M2_10)+Number(props.values.M2_9)+Number(props.values.M2_8)+Number(props.values.M2_7)+Number(props.values.M2_0)+Number(props.values.M2_M)}</td>) : (<td className = "text-center text-danger">{Number(props.values.M2_X)+Number(props.values.M2_10)+Number(props.values.M2_9)+Number(props.values.M2_8)+Number(props.values.M2_7)+Number(props.values.M2_0)+Number(props.values.M2_M)}</td>)}
                </tr>
            </tbody>
        </table>
        </div>
    );
}

export default Table40Shots;