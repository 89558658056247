import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';

function NationalChampionship(){

    /*function showRequestedResults(event){

        switch(event.target.id)
        {
            case "EtapaI":
                window.open(require("../../docs/CN_2023_Etapa_I_results.pdf"));
                break;
            
            case "EtapaII":
                window.open(require("../../docs/CN_2023_Etapa_II_results.pdf"));
                break;

            case "EtapaIII":
                window.open(require("../../docs/CN_2023_Etapa_III_results.pdf"));
                break;
            
            case "EtapaIV":
                window.open(require("../../docs/CN_2023_Etapa_IV_results.pdf"));
                break;

            case "EtapaV":
                window.open(require("../../docs/CN_2023_Etapa_V_results.pdf"));
                break;
            
            case "EtapaVI":
                window.open(require("../../docs/CN_2023_Etapa_VI_results.pdf"));
                break;

            case "EtapaVII":
                window.open(require("../../docs/CN_2023_Etapa_VII_results.pdf"));
                break;
            
            case "EtapaVIII":
                window.open(require("../../docs/CN_2023_Etapa_VIII_results.pdf"));
                break;

            case "Overall":
                window.open(require("../../docs/CN_2023_Overall_results.pdf"));
                break;
            
            default:
                break;
        }

    }*/
    
    return(
        <div>
            <Navbar />
            <div className="container text-center">             
                    
            <iframe
            width="auto" height="900" className="col-lg-12 col-md-12 col-sm-12"
            srcDoc={`
                    <!doctype html>
                    <html>
                    <head>
                        <title>Tickets ordering</title>
                        <meta charset="utf-8">
                    </head>
                    <body align="center">
                        <script type="text/javascript" src="https://l.oveit.com/embed/a958b74de7.js?l=en" data-eventid="a958b74de7" data-init="pending"></script>
                    </body>
                    </html>
                `} 
            />
                    
            </div>
        </div>
    )
}

export default NationalChampionship;