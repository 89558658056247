import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { useState, useEffect } from "react";
import axios from "axios";

function SearchResults(){

    const[searchData,setSearchData]=useState(
        {
            searched:'',
            trial:''
        }
      )

    const [competitorData, setCompetitorData] = useState([]);
    var competitor = [];

    const Server2AppStage = {
        'etapa1' : 'I',
        'etapa2' : 'II',
        'etapa3' : 'III',
        'etapa4' : 'IV',
        'etapa5' : 'V',
        'etapa6' : 'VI',
        'etapa7' : 'VII',
    }

    const handleChange = (event) =>{
        const id = event.target.id;
        const value = event.target.value;
        setSearchData(values => ({...values, [id]: value}))
    }

    const handleSubmit = () =>{

        axios.get('https://nodeppcbackend-production.up.railway.app/api/searchCompetitorResults', {
            params : {
                searched:searchData.searched,
                trial: searchData.trial
              
            }})
            .then((response) => {
            
              if((response.data !== "Invalid!") && ((response.data !== "No data!")))
              {
                 setCompetitorData(response.data);
              }
              else
              {
                alert("No data to display!");
              }
                
           })
           .catch((error) => {});
        
    }

        competitor = competitorData?.map((data,index) =>{
            //console.log(data)
            if(data.WAID === "NA")
            {
                data.WAID = " "
            }
        
                return(
                <tr>
                
                    <td>{data.WAID}</td>
                    <td>{data.FirstName + " "+data.LastName}</td>
                    <td>{data.Classification}</td>
                    <td>{Server2AppStage[data.stage]}</td>
                    <td>{data.TotalX}</td>
                    <td>{data.Points}</td>
                
                </tr>
                );
            
          })

    return(
        <div>
             <Navbar />
             <Hero />

             <div class="container mt-5 ">
                <form class="form-inline d-flex flex-column justify-content-center">
                    <input class="form-control mb-2 mb-sm-2 mr-sm-2 " type="search" id='searched' placeholder="WAID / Nume Complet" aria-label="Search" onChange={handleChange}></input>
                    <div class="form-group mb-2 mb-sm-2 mr-sm-2 ">
                        <select class="form-control" id="trial" onChange={handleChange}>
                            <option selected>Proba</option>
                            <option value="R1500">R1500</option>
                            <option value="P1500">P1500</option>
                            <option value="OS1500">OS1500</option>
                            <option value="PCC1500">PCC1500</option>
                            <option value="DR">DR</option>
                            <option value="DP">DP</option>
                            <option value="Open Pistol">Open Pistol</option>
                            <option value="Open Revolver">Open Revolver</option>
                            <option value="SSA P5.5">SSA P5.5</option>
                            <option value="SSA P3.65">SSA P3.65</option>
                            <option value="SR4.25">SR4.25</option>
                            <option value="SR2.75">SR2.75</option>
                            <option value="SR2.75 5 shot">SR2.75 5 shot</option>
                            <option value="SSA P5.5 Special">SSA P5.5 Special</option>
                        </select>
                    </div>

                    <button class="btn btn-warning mb-2 mb-sm-2" type="submit" onClick={handleSubmit}>Cauta</button>
                </form>
            
            <hr></hr>
            <br></br>

            <div class="table-responsive">
            <table className="table table-borderless text-center" style={{ overflowY: 'scroll' }}>
            <thead>
                <tr className="table-warning">
                    <th scope="col">WA1500</th>
                    <th scope="col">Nume</th>
                    <th scope="col">Clasificare</th>
                    <th scope="col">Etapa</th>
                    <th scope="col">X</th>
                    <th scope="col">Punctaj</th>           
                </tr>
            </thead>
            <tbody className="table-secondary">
                {competitor}
            </tbody>
        </table>
        </div>
            </div> 
            
            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            
             <Footer />
        </div>
    )

}

export default SearchResults;