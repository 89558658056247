import {Link} from 'react-router-dom'

function Navbar(){

    return(
        <div>
        <nav class="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="warning">
        <div class="container-fluid">
          <Link to="/Home" className="navbar-brand"><h4> </h4></Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse ms-auto" id="navbarNavDropdown">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <Link to="/Home" className="nav-link active"> Home </Link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Events
                </a>
                <ul class="dropdown-menu">
                  <li><Link to="/Events/National Championship" className="dropdown-item">National Championship</Link></li>
                  <li><Link to="/Events/European Championship" className="dropdown-item">European Championship</Link></li>
                </ul>
              </li>
              <li class="nav-item">
                <Link to="/Results" className="nav-link">Results</Link>
              </li>
              <li class="nav-item">
                <Link to="/Info" className="nav-link">Info</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      </div>

    )

}

export default Navbar;