import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function PdfDownloader(){

    const navigate = useNavigate();

    useEffect(() => {

        axios.get("https://nodeppcbackend-production.up.railway.app/api/downloadPDF",
    {
        timeout:2000,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
    .then((response) => {
        var file_name = window.localStorage.getItem("pdf_file_name")
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name+'_Results.pdf'); 
        document.body.appendChild(link);
        link.click();
       
        navigate("/Admin/Dashboard")
    })
    .catch((error) => console.log(error));

    }, [])

    return(
        <p>PDF download will start in a few seconds...</p>
    );

}

export default PdfDownloader;