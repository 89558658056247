import { useState, useEffect } from "react";
import UpdateCompetitorModal from "./updateCompetitorModal.js";
import axios from "axios";

function OverviewTable(props){

    const Stages = {
        "etapa1": "Etapa I",
        "etapa2": "Etapa II",
        "etapa3": "Etapa III",
        "etapa4": "Etapa IV",
        "etapa5": "Etapa V",
        "etapa6": "Etapa VI",
        "etapa7": "Etapa VII",
        "etapa8": "Cupa Romaniei",
        "ce2024": "Campionat European 2024",
        "ce2024_tm": "Campionat European 2024 Echipe"
      }
    
    const prev_stage = window.localStorage.getItem("prev_stage");
    
    if(prev_stage !== props.stage){
        window.localStorage.setItem("prev_stage", props.stage)
        window.location.reload(false);
    }

    const [competitorData, setCompetitorData] = useState();
    const [total_competitors, setTotalCompetitors] = useState(0);
    const [show, setShow] = useState(false);
    const [WAID, setWAID] = useState("");
    const [Lastname, setLastname] = useState("");
    const [Firstname, setFirstname] = useState("");
    const [Trial, setTrial] = useState("");
    const [Classification, setClassification] = useState("");
    const [teamID, setTeamID] = useState("");

    useEffect(() => {
        axios.get('https://nodeppcbackend-production.up.railway.app/api/overview', {
            params : {
                Stage:props.stage
            }})
        .then((response) => {
            
           setTotalCompetitors(response.data.length);
           setCompetitorData(response.data)
           console.log(response.data.length);
           
        })
     }, [])

     const refreshAfterDelete =  () =>{window.location.reload(false);}

     const handleDelete = (event) =>{

        var data = event.target.id;
        data = data.split(' ');
        console.log(data);

        var waid = '';
        var Lastname = '';
        var Firstname = '';
        var trial = ''

        if(data[0] === ""){
            waid ='NA'
            Lastname=data[2];
            Firstname=data[3];
            trial = data[4];
        }
        else{

            waid =data[0];
            Lastname=data[1];
            Firstname=data[2];
            trial = data[3];
        }
        
        console.log(props.stage, waid, Lastname, Firstname, props.trial )

        axios.delete('https://nodeppcbackend-production.up.railway.app/api/deleteCompetitor',  { params:{
            Stage: props.stage,
            WAID: waid,
            LastName: Lastname,
            FirstName: Firstname,
            Trial: trial
    
     }})
        .then((response) =>{
            if(response.data !== 0){
                refreshAfterDelete();
            }
            console.log(response.data);
        })
     }

     const[searchName,setCompetitorName]=useState(
        {
            Name:""
        
        }
      )

      function handleChange(event)
      {
        const id = event.target.id;
        const value = event.target.value;
        setCompetitorName(values => ({...values, [id]: value}))
    
      }

      useEffect(() =>
    {
        var name = searchName.Name;      
        const showAll = name.length === 0 ? true:false;

        if(showAll === true)
        {
            axios.get('https://nodeppcbackend-production.up.railway.app/api/overview', {
                params : {
                    Stage:props.stage
                }})
            .then((response) => {
                
               setTotalCompetitors(response.data.length);
               setCompetitorData(response.data)
               
            })
        }
        else 
        {
            axios.get('https://nodeppcbackend-production.up.railway.app/api/searchCompetitor', {
            params : {
                Stage:props.stage,
                Name: searchName.Name,
                Trial: "NA"
            }})
            .then((response) => {
            
                setCompetitorData(response.data)
           
            })
        }
        
    },[searchName])

      function showUpdateMod(event)
      {     
            var data = event.target.id;
            data = data.split(',');
            var waid = '';
            var Lastname = '';
            var Firstname = '';
            var trial = '';
            var classification = '';
            var teamID = '';
             
            if(props.stage !== "ce2024_tm"){
                if(data[0].length === 1)
                {
                    waid = '-'
                    Lastname = data[1];
                    Firstname = data[2];
                    trial = data[3];
                    classification = data[4];
                }
                else
                {
                    waid=data[0];
                    Lastname = data[1];
                    Firstname = data[2];
                    trial = data[3];
                    classification = data[4];
                }
            }
            else
            {
                if(data[0].length === 1)
                    {
                        waid = '-'
                        Lastname = data[1];
                        Firstname = data[2];
                        trial = data[3];
                        classification = data[4];
                        teamID = data[5];
                    }
                    else
                    {
                        waid=data[0];
                        Lastname = data[1];
                        Firstname = data[2];
                        trial = data[3];
                        classification = data[4];
                        teamID = data[5];
                    }
            }
            setWAID(waid);
            setLastname(Lastname);
            setFirstname(Firstname);
            setTrial(trial);
            setClassification(classification);
            setTeamID(teamID);
            setShow(true);
            
      }
     
      function handleScorecard(event)
      {
        var data = event.target.id;
        //console.log(data[0], data[1], data[2], data[3], data[4])
        data = data.split(' ');
        console.log(data[0], data[1], data[2], data[3], data[4])
        //console.log( event.target.id )
        var waid = '';
        var Lastname = '';
        var Firstname = '';
        var trial = '';
        var classification='';
    
        if(data[0] === ""){
            
            console.log("Here")
            waid ='NA'
            Lastname=data[2];
            Firstname=data[3];

            for(let index = 4; index < data.length -1; index++)
            {
                if(index === data.length -2)
                {
                    trial = trial + data[index];
                }
                else
                {
                    trial = trial + data[index] + " ";
                }
            }
            classification = data[data.length - 1];

        }
        else{

            waid =data[0];
            Lastname=data[1];
            Firstname=data[2];

            for(let index = 3; index < data.length -1; index++)
            {
                if(index === data.length -2)
                {
                    trial = trial + data[index];
                }
                else
                {
                    trial = trial + data[index] + " ";
                }
            }
            classification = data[data.length - 1];
        }

        console.log(props.stage, waid, Lastname, Firstname, classification, trial )
        
        axios.get('https://nodeppcbackend-production.up.railway.app/api/createScorecard', {
            params : {
                Stage:props.stage,
                waid:waid,
                lastname:Lastname,
                firstname:Firstname,
                classification:classification,
                Trial: trial
            }})
        .then((response) => {
            
           //console.log(response.data);
           //setCompetitorData(response.data)
    
            axios.get('https://nodeppcbackend-production.up.railway.app/api/downloadScorecard', {
                timeout:4000,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }}).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', Firstname+ " " + Lastname+ " "+trial+'_Scorecard.pdf'); 
                    document.body.appendChild(link);
                    link.click();
                   
                })
                
           
        }).catch((error) => console.log(error));
    
      }

     if(props.stage==="ce2024_tm")
     {
        //console.log(competitorData)
        const competitors = competitorData?.map((data,index) =>{
            if(data.WAID === "NA")
            {
                data.WAID = " "
            }
           
            if((index > 0) && (index % 2 ===0))
            {
                return(
                <>
                <tr>

                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>

                </tr>
                <tr>

                    <td><button id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification} style={{background:'transparent', border:'transparent'}}  onClick={handleScorecard}><i className="bi bi-arrow-down" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification}></i></button><button id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification + " " + data.teamID} style={{background:'transparent', border:'transparent'}}  onClick={showUpdateMod}><i className="bi bi-pencil" id = {data.WAID + "," + data.LastName + "," + data.FirstName + "," + data.Trial + "," + data.Classification + "," + data.teamID}></i></button></td>
                    <td>{data.teamID}</td>
                    <td>{data.WAID}</td>
                    <td>{data.LastName}</td>
                    <td>{data.FirstName}</td>
                    <td>{data.REG}</td>
                    <td>{data.Trial}</td>
                    <td>{data.Classification}</td>

                </tr>
                </>
                );
            }
            else
            {
                return(
                    <tr>
    
                        <td><button id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification} style={{background:'transparent', border:'transparent'}}  onClick={handleScorecard}><i className="bi bi-arrow-down" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification}></i></button><button id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification+ " " + data.teamID} style={{background:'transparent', border:'transparent'}}  onClick={showUpdateMod}><i className="bi bi-pencil" id = {data.WAID + "," + data.LastName + "," + data.FirstName + "," + data.Trial + "," + data.Classification+ "," + data.teamID}></i></button></td>
                        <td>{data.teamID}</td>
                        <td>{data.WAID}</td>
                        <td>{data.LastName}</td>
                        <td>{data.FirstName}</td>
                        <td>{data.REG}</td>
                        <td>{data.Trial}</td>
                        <td>{data.Classification}</td>
    
                    </tr>
                    );
            }
        })
    
        
    
        return(
            <div>
                {show && <UpdateCompetitorModal stage={props.stage} waid={WAID} lastname={Lastname} firstname={Firstname} classification = {Classification} trial = {Trial} teamID = {teamID}/>}
                <div className="container-fluid text-center" style={{width:'20vw', marginLeft:"-3.5vw"}}>
                <div className="card shadow-sm">
                    <br></br>
                    <h2>Overview {Stages[props.stage]}</h2>
                    <br></br>
                    <p> Total bilete: {total_competitors}</p>
                </div>
                </div>
    
            <div className="container-fluid text-left">
                     
                    <form >    
                        <input type="text" class="form-control rounded-pill " placeholder="Nume" aria-label="Name" id="Name" onChange={handleChange} style={{position:"absolute", marginTop:"-6vh", marginLeft:"-18vw", width:"250px"}}></input>
                    </form> 
                    
                  
                <div className="scrollit" style={{marginTop:"15vh", marginLeft:"-18vw", width:"900px"}}><table className="table table-borderless text-center">
                <thead>
                    <tr className="table-dark">
                        <th scope="col">Actiuni</th>
                        <th scope="col">Team ID</th>
                        <th scope="col">WA1500</th>
                        <th scope="col">Nume</th>
                        <th scope="col">Prenume</th>
                        <th scope="col">REG</th>
                        <th scope="col">Proba</th>
                        <th scope="col">Clasificare</th>
                        
                    </tr>
                </thead>
                <tbody className="table-secondary">
                    {competitors}
                </tbody>
                </table></div>
             
            </div>
            </div>
        );
     }
     else
     {
     const competitors = competitorData?.map((data,index) =>{
        if(data.WAID === "NA")
        {
            data.WAID = " "
        }

        return(
        <tr>
            
            <td><button id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification} style={{background:'transparent', border:'transparent'}}  onClick={handleScorecard}><i className="bi bi-arrow-down" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification}></i></button><button id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.Classification} style={{background:'transparent', border:'transparent'}}  onClick={showUpdateMod}><i className="bi bi-pencil" id = {data.WAID + "," + data.LastName + "," + data.FirstName + "," + data.Trial + "," + data.Classification}></i></button></td>
            <td>{data.WAID}</td>
            <td>{data.LastName}</td>
            <td>{data.FirstName}</td>
            <td>{data.REG}</td>
            <td>{data.Trial}</td>
            <td>{data.Classification}</td>
            
        </tr>
        );
    })

    

    return(
        <div>
            {show && <UpdateCompetitorModal stage={props.stage} waid={WAID} lastname={Lastname} firstname={Firstname} classification = {Classification} trial = {Trial}/>}
            <div className="container-fluid text-center" style={{width:'20vw', marginLeft:"-3.5vw"}}>
            <div className="card shadow-sm">
                <br></br>
                <h2>Overview {Stages[props.stage]}</h2>
                <br></br>
                <p> Total bilete: {total_competitors}</p>
            </div>
            </div>

        <div className="container-fluid text-left">
                 
                <form >    
                    <input type="text" class="form-control rounded-pill " placeholder="Nume" aria-label="Name" id="Name" onChange={handleChange} style={{position:"absolute", marginTop:"-6vh", marginLeft:"-18vw", width:"250px"}}></input>
                </form> 
                
              
            <div className="scrollit" style={{marginTop:"15vh", marginLeft:"-18vw", width:"900px"}}><table className="table table-borderless text-center">
            <thead>
                <tr className="table-dark">
                    <th scope="col">Actiuni</th>
                    <th scope="col">WA1500</th>
                    <th scope="col">Nume</th>
                    <th scope="col">Prenume</th>
                    <th scope="col">REG</th>
                    <th scope="col">Proba</th>
                    <th scope="col">Clasificare</th>
                    
                </tr>
            </thead>
            <tbody className="table-secondary">
                {competitors}
            </tbody>
            </table></div>
         
        </div>
        </div>
    );
}
}

export default OverviewTable;