import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { Link } from 'react-router-dom';

function Info(){

    function showTutorial(event){

      switch(event.target.id){
        case "tickets-tutorial":
          window.open(require("../../docs/INSTRUCTIONS FOR ORDERING A MATCH TICKET.pdf"));
          break;

        default:
          break;
      }

    }
    
    return(
        <div>
          <Navbar />
          <Hero />
          <section id="ticket-ordering">
          <div className="container"> 
              <br></br><br></br>
              <h2>Match ticket ordering</h2>
              <div className="card shadow-lg text-center">
                <div className="card-body">
                    <br></br><br></br>
                    <button id="tickets-tutorial" className="btn btn-light" onClick={showTutorial}>How to order a ticket?</button>
                    <br></br><br></br><br></br>
                </div>
              </div>      
          </div>
          </section>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
                

            <div className="row">
                <hr></hr>
            </div>

            <section id="contact">
              <div className="container">
                  <br></br><br></br>
                  <h2>Contact</h2>
                  <div className="card shadow-lg text-left">
                      <div className="card-body">
                        <br></br><br></br>
                            <div className="row">
                              <div className="col-sm-2"><h5 >Email address:</h5></div>
                              <div className="col">ppcromaniaro@gmail.com</div>
                              <div className="col"></div>
            
                            </div>
                            <br></br>
                            <br></br>
                            <div className="row">
                              <div className="col-sm-2"><h5>Address:</h5></div>
                              <div className="col">Intrarea Poligonului, Nr.1, Joița 087150</div>
                              <div className="col"></div>
                              
                              
                            </div>

                        <br></br><br></br>
                      </div>
                  </div>   

              </div>
            </section>

            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            <Footer />

        </div>
    )
}

export default Info;