import { useState, useEffect } from "react";
import axios from "axios";
function AdaugareConcurent(props){
    
    const[addCompetitorForm,setCompetitorData]=useState(
        {
            WAID:"",
            Nume:"",
            Prenume: "",
            Regiune: "",
            Clasificare: "",
            Proba: "",
            TeamID:""
        }
      )


      const [isSafe, setSafe] = useState(false)
      function handleChange(event)
      {
        const id = event.target.id;
        const value = event.target.value;
        setCompetitorData(values => ({...values, [id]: value}))
      }

      function handleSubmit(event)
      {
       var waid = addCompetitorForm.WAID
       if(addCompetitorForm.WAID === ""){
            waid = "NA"
       }
       axios.post("https://nodeppcbackend-production.up.railway.app/api/addCompetitor",{
            Stage: props.stage,      
            WAID:  waid,
            LastName: addCompetitorForm.Nume,
            FirstName: addCompetitorForm.Prenume,
            REG: addCompetitorForm.Regiune,
            Classification: addCompetitorForm.Clasificare,
            Trial: addCompetitorForm.Proba,
            TeamID: addCompetitorForm.TeamID
       })
       .then((response) =>{
            if(response.data === 1){
                alert("Concurent adaugat cu succes!");
                window.location.reload(false);
            }
       })
       .catch((error) => {
            alert("Something went wrong!");
       })
      }

      useEffect(() => {
        setSafe(false)
        var Nume = String(addCompetitorForm.Nume)
        var Prenume = String(addCompetitorForm.Prenume)
        var Regiune = String(addCompetitorForm.Regiune)
        var Clasificare = String(addCompetitorForm.Clasificare)
        var Proba = String(addCompetitorForm.Proba)
        var TeamID = String(addCompetitorForm.TeamID)
        if((props.stage !== "ce2024_tm") && (Nume.trim().length !== 0)&&(Prenume.trim().length !== 0)&&(Regiune.trim().length !== 0)&&(Clasificare.trim().length !== 0)&&(Proba.trim().length !== 0))
        {
            setSafe(true)
        }
        else if((props.stage === "ce2024_tm") && (TeamID.trim().length !== 0)&& (Nume.trim().length !== 0)&&(Prenume.trim().length !== 0)&&(Regiune.trim().length !== 0)&&(Clasificare.trim().length !== 0)&&(Proba.trim().length !== 0))
        {
            setSafe(true)
        }
     }, [addCompetitorForm])
    if(props.stage !== "ce2024_tm")
    {
     return(
        <div className="container" style={{width:'500px', marginTop: "20vh", marginLeft: "-10vw"}}>
            <div className="card shadow-lg">
                <div className="card-body p-5 text-center">
                    <form>
                           
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="WAID" aria-label="WAID" id="WAID" onChange={handleChange}></input>
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Nume" aria-label="Nume" id="Nume" required onChange={handleChange}></input>
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Prenume" aria-label="Prenume" id="Prenume" required onChange={handleChange}></input>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Regiune" id="Regiune" onChange={handleChange}>
                            <option selected>Regiune</option>
                            <option value="AUT">AUT</option>
                            <option value="BEL">BEL</option>
                            <option value="BGR">BGR</option>
                            <option value="HRV">HRV</option>
                            <option value="CYP">CYP</option>
                            <option value="CZE">CZE</option>
                            <option value="DNK">DNK</option>
                            <option value="EST">EST</option>
                            <option value="FIN">FIN</option>
                            <option value="FRA">FRA</option>
                            <option value="DEU">DEU</option>
                            <option value="GRC">GRC</option>
                            <option value="HUN">HUN</option>
                            <option value="IRL">IRL</option>
                            <option value="ITA">ITA</option>
                            <option value="LVA">LVA</option>
                            <option value="LTU">LTU</option>
                            <option value="LUX">LUX</option>
                            <option value="MDA">MDA</option>
                            <option value="MLT">MLT</option>
                            <option value="NLD">NLD</option>
                            <option value="POL">POL</option>
                            <option value="PRT">PRT</option>
                            <option value="ROU">ROU</option>
                            <option value="SVK">SVK</option>
                            <option value="SVN">SVN</option>
                            <option value="ESP">ESP</option>
                            <option value="SWE">SWE</option>
                            <option value="GBR">GBR</option>
                        </select>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Clasificare" id="Clasificare" onChange={handleChange}>
                            <option selected>Clasificare</option>
                            <option value="HighMaster">High Master</option>
                            <option value="Master">Master</option>
                            <option value="Expert">Expert</option>
                            <option value="Sharpshooter">Sharpshooter</option>
                            <option value="Marksman">Marksman</option>
                            <option value="Unclassified">Unclassified</option>
                        </select>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Proba" id="Proba" onChange={handleChange}>
                            <option selected>Proba</option>
                            <option value="R1500">R1500</option>
                            <option value="P1500">P1500</option>
                            <option value="OS1500">OS1500</option>
                            <option value="PCC1500">PCC1500</option>
                            <option value="DR">DR</option>
                            <option value="DP">DP</option>
                            <option value="Open Pistol">Open Pistol</option>
                            <option value="Open Revolver">Open Revolver</option>
                            <option value="SSA P5.5">SSA P5.5</option>
                            <option value="SSA P3.65">SSA P3.65</option>
                            <option value="SR4.25">SR4.25</option>
                            <option value="SR2.75">SR2.75</option>
                            <option value="SR2.75 5 shot">SR2.75 5 shot</option>
                            <option value="SSA P5.5 Special">SSA P5.5 Special</option>
                           
                        </select>
                        <button type="submit" class="w-50 btn btn-success rounded-pill mt-5" onClick={handleSubmit} disabled={isSafe === true ? false:true }>Adauga</button>
                    </form>
                </div>
            </div> 
        </div>
    );
    }
    else
    {
        return(
            <div className="container" style={{width:'500px', marginTop: "20vh", marginLeft: "-10vw"}}>
                <div className="card shadow-lg">
                    <div className="card-body p-5 text-center">
                        <form>
                            <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="ID echipa (9 cifre)" aria-label="TeamID" id="TeamID" onChange={handleChange}></input>    
                            <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="WAID" aria-label="WAID" id="WAID" onChange={handleChange}></input>
                            <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Nume" aria-label="Nume" id="Nume" required onChange={handleChange}></input>
                            <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Prenume" aria-label="Prenume" id="Prenume" required onChange={handleChange}></input>
                            <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Regiune" id="Regiune" onChange={handleChange}>
                                <option selected>Regiune</option>
                                <option value="AUT">AUT</option>
                                <option value="BEL">BEL</option>
                                <option value="BGR">BGR</option>
                                <option value="HRV">HRV</option>
                                <option value="CYP">CYP</option>
                                <option value="CZE">CZE</option>
                                <option value="DNK">DNK</option>
                                <option value="EST">EST</option>
                                <option value="FIN">FIN</option>
                                <option value="FRA">FRA</option>
                                <option value="DEU">DEU</option>
                                <option value="GRC">GRC</option>
                                <option value="HUN">HUN</option>
                                <option value="IRL">IRL</option>
                                <option value="ITA">ITA</option>
                                <option value="LVA">LVA</option>
                                <option value="LTU">LTU</option>
                                <option value="LUX">LUX</option>
                                <option value="MLT">MLT</option>
                                <option value="NLD">NLD</option>
                                <option value="POL">POL</option>
                                <option value="PRT">PRT</option>
                                <option value="ROU">ROU</option>
                                <option value="SVK">SVK</option>
                                <option value="SVN">SVN</option>
                                <option value="ESP">ESP</option>
                                <option value="SWE">SWE</option>
                                <option value="GBR">GBR</option>
                            </select>
                            <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Clasificare" id="Clasificare" onChange={handleChange}>
                                <option selected>Clasificare echipa</option>
                                <option value="HighMaster">High Master</option>
                                <option value="Master">Master</option>
                                <option value="Expert">Expert</option>
                                <option value="Sharpshooter">Sharpshooter</option>
                                <option value="Marksman">Marksman</option>
                                <option value="Unclassified">Unclassified</option>
                            </select>
                            <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Proba" id="Proba" onChange={handleChange}>
                                <option selected>Proba</option>
                                <option value="Pistol">Pistol</option>
                                <option value="Revolver">Revolver</option>
                                <option value="Optic">Optic</option>
                                <option value="PCC">PCC</option>
                            </select>
                            <button type="submit" class="w-50 btn btn-success rounded-pill mt-5" onClick={handleSubmit} disabled={isSafe === true ? false:true }>Adauga</button>
                        </form>
                    </div>
                </div> 
            </div>
        );
    }
}
export default AdaugareConcurent;