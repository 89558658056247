import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { Link } from 'react-router-dom';

function Home(){
    return(
        
        <div>  
            <Navbar />
             <Hero />
             <div className="row">
             <section id="sponsor-logo">
                <div className = "row">
                    <div className="col"></div>
                    <div className = "col-md-4 col-sm-6">
                    <div className="container text-center" style={{ marginRight: '6vw' }}> 
                           <Link to="https://www.merkle-tuning.de/" style={{ marginRight: '5vw' }}> 
                                <img className="img-fluid" src="Merkle Logo Vorderseite.jpg" alt="Merkle" style={{height:45, width:45}}></img>
                           </Link>

                           <Link to="https://kortharms.com/" style={{ marginRight: '6vw' }}> 
                                <img className="img-fluid" src="Korth.jpg" alt="Korth" style={{height:40, width:40}} ></img>
                           </Link>

                           <Link to="https://www.novamodul.com/" style={{ marginRight: '6vw' }}> 
                                <img className="img-fluid" src="Nova Module.png" alt="Nova Module" style={{height:60, width:100}}></img>
                           </Link>
                           <Link to="https://stradef.ro/" style={{ marginRight: '5vw' }}> 
                                <img className="img-fluid" src="Strategic Defence.png" alt="Strategic Defence" style={{height:50, width:50}}></img>
                           </Link>
                    </div>
                    </div>
                    <div className="col"></div>

                 
                   
                </div>
                
            </section>
                <hr></hr>
            </div>
             <br></br> 
             <section id="ticket-ordering">
                <div className = "row">
                    <div className="col"></div>
                    
                    <div className = "col-md-4 col-sm-6">
                        <div className="container text-center">            
                           <Link to="/Events/European Championship"> 
                                <img className="img-fluid" src="eclogo.jpeg" alt="ordertickets" style={{height:300, width:300}}></img>
                           </Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className = "d-block d-sm-none">
                            <br></br>
                            <hr></hr>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <div className = "col-md-4 col-sm-6">
                        <div className="container text-center">     
                            <Link to="/Events/National Championship" style={{textDecoration:'none', color:'black'}}>
                                 <img className="img-fluid" src="nclogo.png" alt="ordertickets"></img>
                                 <br></br>
                                 <br></br>
                                 <p className="text-left">Campionatul National FRTS WA1500 PPC 2024 Etapa VI</p>    
                            </Link>
                        </div>
                    </div>

                    <div className="col"></div>
                   
                </div>
            </section> 

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>   
             
            <div className="row">
                <hr></hr>
            </div>

            <section id="sponsors-description">
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                        <h2>Dear Fellow Participants at the EC2024 in Joita,</h2>
                    <p className="text-left"> It is our outmost pleasure to inform you that the <b>tombola</b> to be organized at the end of the <b>EC2024</b> will distribute among the participants a plethora of prizes offered by our sponsors. </p>
                    <p className="text-left"> We are very proud that <b>Ceska Zbrojovka</b>, the well-known firearms producer, through <b>Material Grup</b>, is sponsoring a <b>Shadow 2 Target</b> – a brand new model still to be revealed! </p>
                    <p className="text-left"> We are also happy to announce that Romanian firearms producers also sponsor the tombola: <b>Nova Modul</b> through <b>Strategic Defense</b> offer a <b>CTS 9 Tactical</b> carbine.</p>
                    <p className="text-left"> The list continues with <b>KMR</b>, <b>Merkle Tunning</b> and <b>Triebel Sportwaffen</b>, who sponsor a <b>KMR Spectra</b> pistol! Thank you very much!</p>
                    <p className="text-left"> Last but not least, the producer of the very fine revolvers and the constant sponsor of <b>Romanian PPC</b>, <b>Korth</b> is offering a <b>four-inch</b> revolver!</p>
                    <p className="text-left"> <b>ABD Computer</b> will sponsor <b>CE2024</b> with <b>5 x Laptop Lenovo X1 Carbon</b> the best Laptop Business in the world.</p>
                    </div>
                    
                    <div className="col">
                        
                    </div>
                </div>
            </section>
            <br></br>
            <br></br>
            <br></br>
            
            <div className="row">
                <hr></hr>
            </div>
            <section id="Introduction">
                <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                        <h2>Home</h2>
                    <p className="text-left">Doamnelor și domnilor, trăgători și entuziaști, bine ați venit într-o destinație de neegalat în care converg precizia, îndemânarea și adrenalina - poligonul nostru de tragere de ultimă oră. Astăzi, pornim într-o călătorie pentru a explora un paradis conceput pentru cei care caută să-și perfecționeze trăsăturile, să se cufunde în lumea împușcăturii și să-și ridice abilitățile la noi culmi. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="weapons.jpeg" alt="weapons"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>
            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                

            <div className="row">
                <hr></hr>
            </div>


            <section id="home-secII">   
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                    <h2>O poartă către precizie</h2>
                    <p className="text-left">Amplasat în aceste ziduri este un tărâm dedicat artei și științei împușcăturii. Poligonul nostru de tragere nu este doar un loc pentru a trage cartușele; este un spațiu în care tirul devine o formă de artă. Am creat un mediu care se adresează începătorilor dornici de a învăța, practicienilor calificați care își perfecționează meseria și profesioniștilor care își perfecționează tehnicile. Acesta este un loc în care fiecare împușcătură trasă este un pas către măiestrie. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="shooter.jpeg" alt="shooter"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>

            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                

            <div className="row">
                <hr></hr>
            </div>

            <section id="home-secIII">   
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                    <h2>Dincolo de Bang</h2>
                    <p className="text-left">Dar raza noastră de acțiune nu se referă doar la sunetul focuri de armă; este vorba despre promovarea disciplinei, controlului și concentrării. Este vorba despre simfonia respirației, alinierea vederii și apăsarea declanșatorului care creează o fotografie armonioasă. Este vorba despre depășirea granițelor, cucerirea provocărilor și îmbrățișarea creșterii personale. Aici vei descoperi o abordare holistică a tirului – una care se extinde dincolo de momentul aprinderii. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="3.jpeg" alt="shooters"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>

            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                

            <div className="row">
                <hr></hr>
            </div>

            <section id="home-secIV">   
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                    <h2>Siguranta pe primul loc</h2>
                    <p className="text-left">Înainte de a pătrunde mai adânc în inima poligonului nostru de tragere, permiteți-ne să subliniem angajamentul nostru neclintit față de siguranță. Siguranța nu este doar o prioritate; este înrădăcinată în fiecare aspect al operațiunilor noastre. Din momentul în care pășiți pe poligon, veți găsi o respectare meticuloasă a protocoalelor, îndrumarea ofițerilor de poligon cu experiență și un mediu securizat care vă permite să vă concentrați numai pe călătoria dvs. de tir. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="range.jpeg" alt="reange"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>

            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                
        <Footer />
            
        </div>
        
    )
}

export default Home;