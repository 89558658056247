function Hero(){
    return(
        <section id="Hero" className="h-50 p-3 mb-2 bg-warning">
                <div className="row">
                    
                </div>
                <div className="row">
                    <br></br>
                </div>
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col-8">
                        <p className="text-left fw-bold" style={{fontSize:"5.5vh"}}>PPC Arena</p>
                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                </div>
                <div className="row">

                    <div className="col"></div>

                    <div className="col-8">
                        <p className="text-left fw-bold" style={{fontSize:"2vh"}}>Home of Romanian Professional Shooters</p>
                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    
                </div>

                <div className="row">
                    <div className="col"> <br></br></div>
                </div>
            </section>
    )
}

export default Hero;