import axios from "axios";
import { useNavigate} from "react-router";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function PdfGeneration(props){

    const navigate = useNavigate();

        useEffect(() => {

            axios.get("https://nodeppcbackend-production.up.railway.app/api/generatePDF",
        {
            params:{
                Stage:props.stage
            }   
        })
        .then((response) => {
            
                let name = props.stage === "etapa8" ? "Cupa Romaniei" : props.stage
                window.localStorage.setItem("pdf_file_name", name);
            
        })
        .catch((error) => console.log(error));

        }, [])

        return(
            <>
                <p>PDF results generated...<Link to="/Downloads">Click here to download</Link></p>
                
            </>
        );
}

export default PdfGeneration;